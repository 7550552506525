const burger = document.getElementsByClassName('hamburger');

// console.log( burger, burger.length );

if(burger.length !== 0){
    burger[0].addEventListener("click", function() {

        const burgerBtn = this;
        const navMenu   = document.querySelector('.main-nav');
        const bodyTag   = document.getElementsByTagName("body");

        navMenu.classList.add('transition-transform');
        navMenu.classList.add('ease-in-out');
        navMenu.classList.add('duration-300');
        navMenu.classList.toggle('-translate-y-full');
        burgerBtn.classList.toggle("is-active");

        bodyTag[0].classList.toggle("menu-is-active");


        navMenu.ontransitionend = () => {
            navMenu.classList.remove('transition-transform');
            navMenu.classList.remove('ease-in-out');
            navMenu.classList.remove('duration-300');
        };

    }, false);

}